<template>
  <div class="contact-cta absolute text-right">
    <h2 class="uppercase">Contact Us</h2>
    <a href="mailto:hi@homepageforchange.com" class="swipe">hi@homepageforchange.com</a>
  </div>
</template>

<script>
export default {}
</script>
